import Caption from "./compnents/Caption";
import NextBtn from "./compnents/NextBtn";
import { Dropdown, Radio } from "semantic-ui-react";
import { useState, useEffect, useMemo } from "react";
import InputField from "./compnents/InputField";
import { getS3Credentials, uploadPhotoToS3 } from "./compnents/S3";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import { traceDetailsOfIncidentTakePhoto } from "./utils/tracer";
import "./incidentDetails.css"

// eslint-disable-next-line no-undef
registerPlugin(
  FilePondPluginImageTransform,
  FilePondPluginImageResize,
  FilePondPluginImageCrop,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

function genFormItem(props, values, setValue) {
  const name = props.displayName || props.name;
  const asterisk = props.isMandatory;
  const title = asterisk ? <div>{name}<span className="font-semibold text-red-500">  *</span></div> : <div>{name}</div>
  if (props.type === "list") {
    return (
      <div key={props.name}>
        <div className="input-label-text-style mb-5 font-medium mt-6">
          {title}
        </div>
        <Dropdown
          className={props.name}
          placeholder={props.defaultValue}
          fluid
          selection
          value={values[props.name]}
          onChange={(event, item) => {
            let v = item.value;
            setValue((prev) =>
              Object.assign(Object.assign({}, prev), {
                [props.name]: v,
              })
            );
          }}
          options={props.options.map((o) => {
            return { key: o, text: o, value: o };
          })}
        />
      </div>
    );
  } else if (props.type === "switch") {
    return (
      <div key={props.name} className="input-label-text-style font-medium mt-6">
        {title}
        <Radio
          className={`float-right ${props.name}`}
          toggle
          onChange={(event, item) => {
            let checked = item.checked;
            setValue((prev) =>
              Object.assign(Object.assign({}, prev), {
                [props.name]: checked,
              })
            );
          }}
          defaultChecked={
            values[props.name] === undefined
              ? props.defaultValue === "true"
              : values[props.name]
          }
        />
      </div>
    );
  } else if (props.type === "text") {
    return (
      <InputField
        key={props.name}
        onChange={(e) => {
          let v = e.target.value;
          setValue((prev) =>
            Object.assign(Object.assign({}, prev), {
              [props.name]: v,
            })
          );
        }}
        textCls="border rounded-lg pl-5"
        containerCls={`${props.name} flex-col items-start`}
        value={values[props.name]}
        labelName={props.displayName || props.name}
        required={asterisk}
        splitHeight={true}
      />
    );
  } else if (props.type === "multiple") {
    return (
      <div key={props.name}>
        <div className="input-label-text-style mb-5 font-medium mt-6">
          {title}
        </div>
        <Dropdown
          className={props.name}
          placeholder={props.defaultValue}
          fluid
          selection
          multiple
          onChange={(event, item) => {
            let v = item.value;
            setValue((prev) =>
              Object.assign(Object.assign({}, prev), {
                [props.name]: v,
              })
            );
          }}
          options={props.options.map((o) => {
            return { key: o, text: o, value: o };
          })}
        />
      </div>
    );
  }
}

export default function IncidentDetails({
  incidentType,
  incidentDetails,
  setLocPhoto,
  locPhoto,
  setIncidentDetails,
  images,
  setImages,
  setUploadImages,
  nextTab,
}) {
  const [maxUploadImages, noop] = useState(
    // incidentType.maxNumberOfImages ? incidentType.maxNumberOfImages : 5
    incidentType.incident && incidentType.incident.maxNumberOfImages
      ? incidentType.incident.maxNumberOfImages
      : 5
  );
  const [mandatoryFormItems, setMandatoryFormItems] = useState([]);
  const [optionalFormItems, setOptionalFormItems] = useState([]);
  const [active, setActive] = useState(false);
  useEffect(()=>{
    let act = true;
    mandatoryFormItems.forEach(item => {
      if (item.type !== 'switch') {
        if (!(incidentDetails[item.name] &&  item.type)) act = false
      }
    });
    setActive(act);
  }, [mandatoryFormItems, incidentDetails]);
  const FP = useMemo(
    () => (
      <FilePond
        imageTransformOutputStripImageHead={false}
        allowImageCrop={true}
        allowImageResize={true}
        imageResizeTargetWidth={2048}
        imageResizeTargetHeight={2048}
        imageResizeMode={"cover"}
        imageResizeUpscale={false}
        acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
        imageCropAspectRatio="1:1"
        imagePreviewMarkupFilter={(m) => console.log(m)}
        files={images}
        beforeAddFile={() => {
          const qr = window.location.pathname.indexOf("/qr/") === 0 ? 1 : 0;
          traceDetailsOfIncidentTakePhoto(
            incidentType.authority.id,
            incidentType.incident.name,
            qr
          );
        }}
        onpreparefile={async (file, processedFile) => {
          let link = document.createElement("a"); // Or maybe get it from the current document
          link.href = URL.createObjectURL(processedFile);
          link.download = "aDefaultFileName.jpg";
          // link.innerHTML = "Click here to download the file";
          document.body.appendChild(link); // Or append it whereever you want

          try {
            const s3Credential = await getS3Credentials();
            let res = await uploadPhotoToS3(processedFile, s3Credential);
            setUploadImages({
              type: "image",
              bucket: res.bucket,
              key: res.key,
            });
          } catch (e) {
            console.log(e);
          }
          setImages((prev) => {
            if (prev.map((f) => f.name).indexOf(processedFile.name) >= 0)
              return prev;
            else return [processedFile, ...prev];
          });
        }}
        beforeRemoveFile={(file) => {
          let filename = file.file.name;
          setImages((prev) => {
            for (let i in prev) {
              let pname = prev[i].name;
              if (pname === filename) {
                prev.splice(i, 1);
                setUploadImages(pname);
                return prev.slice();
              }
            }
            return prev;
          });
          if (locPhoto[0] && filename === locPhoto[0].name) setLocPhoto([]);
        }}
        allowReorder={false}
        allowMultiple={true}
        maxFiles={maxUploadImages}
        labelIdle="Add a photo"
      />
    ),
    [maxUploadImages]
  );

  useEffect(() => {
    let mandatory = incidentType.additionalFields.filter((f) => f.isMandatory);
    let optional = incidentType.additionalFields.filter((f) => !f.isMandatory);
    mandatory.sort((a, b) => a.sortOrder - b.sortOrder);
    optional.sort((a, b) => a.sortOrder - b.sortOrder);

    setMandatoryFormItems(mandatory);
    setOptionalFormItems(optional);

    for (let item of mandatory) {
      if (
        item.hasOwnProperty("defaultValue") &&
        item.defaultValue !== "" &&
        item.defaultValue !== null
      ) {
        let dv = item.defaultValue;
        if (dv === "true") dv = true;
        if (dv === "false") dv = false;
        if (!incidentDetails.hasOwnProperty(item.name)) {
          setIncidentDetails((prev) =>
            Object.assign(Object.assign({}, prev), { [item.name]: dv })
          );
        }
      }
    }
    for (let item of optional) {
      if (
        item.hasOwnProperty("defaultValue") &&
        item.defaultValue !== "" &&
        item.defaultValue !== null
      ) {
        let dv = item.defaultValue;
        if (dv === "true") dv = true;
        if (dv === "false") dv = false;
        if (!incidentDetails.hasOwnProperty(item.name)) {
          setIncidentDetails((prev) =>
            Object.assign(Object.assign({}, prev), { [item.name]: dv })
          );
        }
      }
    }
  }, []);
  return (
    <div className="flex items-center flex-col">
      <div className="type-caption-info">Incident details</div>
      <div className="w-full pt-6 flex flex-col">
        {FP}
        <p className="photo-maximum-tips">Maximum {maxUploadImages} photos</p>
        {mandatoryFormItems.map((item) =>
          genFormItem(item, incidentDetails, setIncidentDetails)
        )}
        {optionalFormItems.map((item) =>
          genFormItem(item, incidentDetails, setIncidentDetails)
        )}
        <InputField
          className="description"
          labelName="Description"
          textCls="border rounded-lg pl-5 pt-4"
          containerCls="flex-col items-start h-72"
          multipleline={true}
          onChange={(e) => {
            let v = e.target.value;
            setIncidentDetails((prev) =>
              Object.assign(Object.assign({}, prev), {
                description: v,
              })
            );
          }}
          value={incidentDetails.description}
        />
      </div>
      <NextBtn
        active={active}
        nextTab={nextTab}
      />
    </div>
  );
}
