import GoogleMapReact from "google-map-react";
import { useState, useRef, useEffect } from "react";
import "./Map.css";
import "./Marker.css";
import Loading from "./Loading";
import {
  traceIncidentLocationActiveInput,
  traceIncidentLocationManualSelect,
} from "../utils/tracer";
import { getUrlQueryObject, isEmbedLocation } from "../utils/common";
import { EMBED_LOCATION } from "../constants";

export default function Map({
  apikey,
  defaultCenter,
  alert,
  disableNext,
  enableNext,
  center,
  setCenter,
}) {
  const [maps, setMaps] = useState();
  const [draggable, setDraggable] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(3);
  const [showPin, setShowPin] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(center);
  const [loading, setLoading] = useState(false);
  const searchInput = useRef();
  const traceActiveInput = () => {
    traceIncidentLocationActiveInput();
    searchInput.current.removeEventListener("focus", traceActiveInput, false);
  };
  useEffect(() => {
    if (searchInput && searchInput.current) {
      searchInput.current.addEventListener("focus", traceActiveInput, false);
    }
    return () =>
      searchInput &&
      searchInput.current &&
      searchInput.current.removeEventListener("focus", traceActiveInput, false);
  }, []);
  const handleApiLoaded = ({ map, maps }) => {
    setMaps(maps);
    let resetBtn = document.createElement("img");
    resetBtn.src = "/imgs/Vector.svg";
    resetBtn.classList.add("mylocation-btn");
    map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(resetBtn);
    resetBtn.addEventListener("click", (e) => {
      if ("geolocation" in navigator) {
        resetBtn.classList.add("flickering");
        setLoading(true);
        navigator.geolocation.getCurrentPosition(
          (position) => {
            updateCurrentPosition({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            resetBtn && resetBtn.classList.remove("flickering");
            setLoading(false);
          },
          (err) => {
            setLoading(false);
            alert(err);
            let resetPositionBtn = document.querySelector(".mylocation-btn");
            resetPositionBtn && resetPositionBtn.classList.remove("flickering");
            updateCurrentPosition(defaultCenter);
          }
        );
      }
    });
    let searchBox = new maps.places.Autocomplete(searchInput.current, {
      componentRestrictions: { country: ["au", "nz"] },
    });
    searchBox.addListener("place_changed", () => {
      let chosenPlace = searchBox.getPlace();
      if (chosenPlace) {
        enableNext();
        let loc = chosenPlace.geometry.location;
        updateCurrentPosition({ lat: loc.lat(), lng: loc.lng() });
        traceIncidentLocationManualSelect();
      }
    });
    if (
      "geolocation" in navigator &&
      center.loc === defaultCenter.loc &&
      center.lat === defaultCenter.lat
    ) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          updateCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          alert();
          let loc = defaultCenter;
          if (isEmbedLocation()) {
            loc = getUrlQueryObject(EMBED_LOCATION);
            Object.keys(loc).forEach((k) => {
              loc[k] = parseFloat(loc[k]);
            });
          }
          updateCurrentPosition(loc);
        }
      );
    }
  };
  const updateCurrentPosition = (loc) => {
    setCenter(loc);
    if (loc.lat !== defaultCenter.lat && loc.lng !== defaultCenter.lng) {
      setShowPin(true);
      setZoomLevel(loc.zoom || 18);
    } else {
      if (searchInput.current) searchInput.current.value = "Australia";
      setShowPin(false);
      setZoomLevel(3);
      return;
    }
    setMarkerPosition(loc);
    let resetPositionBtn = document.querySelector(".mylocation-btn");
    resetPositionBtn && resetPositionBtn.classList.remove("flickering");
    setMaps((maps) => {
      if (maps) {
        let geocoder = new maps.Geocoder();
        geocoder.geocode({ location: loc }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              searchInput.current.value = results[0].formatted_address;
              setCenter({ address: results[0].formatted_address });
            }
          }
        });
      }
      return maps;
    });
  };

  const markerMouseDown = () => {
    setDraggable(false);
  };
  const markerMouseUp = (key, childProps, mouse) => {
    setDraggable(true);
    updateCurrentPosition(mouse);
  };
  const markerMouseMove = (key, childProps, mouse) => {
    setMarkerPosition({ lat: mouse.lat, lng: mouse.lng });
  };
  useEffect(() => {
    updateCurrentPosition(center);
  }, [center.lat, center.lng]);
  return (
    <>
      <div className="mt-8 md:mt-12 mb-6 relative w-full  h-14">
        <input
          className="font-input text-input-color text-lg h-full w-full border-default-color rounded-lg border pl-3"
          placeholder="Enter your address"
          value={center.address}
          onChange={(e) => {
            setCenter({ address: e.target.value });
            disableNext();
          }}
          ref={searchInput}
          type="text"
        />
      </div>
      <div className="w-full h-map-md md:h-map-lg relative">
        {loading ? <Loading className="mt-0" /> : <></>}
        <GoogleMapReact
          bootstrapURLKeys={{ key: apikey, libraries: "places" }}
          center={{ lat: center.lat, lng: center.lng }}
          draggable={draggable}
          zoom={zoomLevel}
          yesIWantToUseGoogleMapApiInternals
          onChildMouseDown={markerMouseDown}
          onChildMouseUp={markerMouseUp}
          onChildMouseMove={markerMouseMove}
          onGoogleApiLoaded={handleApiLoaded}
          options={(maps) => {
            return {
              mapTypeControl: true,
              mapTypeId: maps.MapTypeId.ROADMAP,
              mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
              },
            };
          }}
        >
          <Marker
            className={!showPin ? "invisible" : ""}
            lat={markerPosition.lat}
            lng={markerPosition.lng}
          />
        </GoogleMapReact>
      </div>
    </>
  );
}
const Marker = (props) => {
  return (
    <>
      <div className={`${props.className} pin`} />
      <div className={`${props.className} pulse`} />
    </>
  );
};
