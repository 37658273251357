import "./index.css";
import { Loader } from "semantic-ui-react";

// status 2 success, 1 scanning
export default function QrStatus({ status }) {
  return (
    <div className="QrStatus">
      <div className="qr-status-icon">
        {status === 2 ? (
          <img
            src="/imgs/status-success.png"
            style={{ width: "80px", height: "80px" }}
          />
        ) : (
          <div className="qr-scanning">
            <img src="/imgs/scan-qr-tip.png" className="scan-qr-tip" />
            <Loader size="massive"></Loader>
          </div>
        )}
      </div>
      <div
        className={`qr-status-text ${
          status === 2 ? "qr-status-text__success" : "qr-status-text__scanning"
        }`}
      >
        {status === 2 ? "Scan successful" : "Scanning QR code..."}
      </div>
    </div>
  );
}
