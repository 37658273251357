import { useState } from "react";

export default function useModal() {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState("");
  const showModal = (content) => {
    setContent(content);
    setVisible(true);
  };
  const hideModal = () => {
    setContent("");
    setVisible(false);
  };

  return [visible, content, showModal, hideModal];
}
