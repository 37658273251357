import "./DropDown.css";
import { useEffect, useState, useRef } from "react";

export default function DropDown(props) {
  const { defaultValue, iconSrc, type } = props;
  const [arrowStatus, setArrowStatus] = useState("down");
  const containerRef = useRef();
  const arrowRef = useRef();
  const optionsRef = useRef();
  const showOptions = () => {
    optionsRef.current.classList.remove("hidden");
  };
  const hideOptions = () => {
    optionsRef.current.classList.add("hidden");
  };
  const clickSelect = () => {
    if (arrowStatus === "down") {
      setArrowStatus("up");
      arrowRef.current.style = "transform: rotate(180deg)";
      containerRef.current.classList.add("dropped");
      showOptions();
    } else {
      setArrowStatus("down");
      arrowRef.current.style = "transform: rotate(0deg)";
      containerRef.current.classList.remove("dropped");
      hideOptions();
    }
  };
  useEffect(() => {
    if (type === "search-input") {
      let search = document.querySelector("#search-input");
      search.addEventListener("focusout", (event) => {
        hideOptions();
      });
    }
  });
  const searchOption = (e) => {
    props.setInputValue(e.target.value);
    let ops = document.querySelectorAll("#search-div li");
    for (let op of ops) {
      if (
        op.textContent.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0
      ) {
        op.classList.remove("hidden");
      } else {
        op.classList.add("hidden");
      }
    }
    if (e.target.value.trim() === "") {
      containerRef.current.classList.remove("dropped");
    } else {
      containerRef.current.classList.add("dropped");
    }
    showOptions();
  };
  return (
    <div
      ref={containerRef}
      className={` ${props.containerCls} type-select-text-style border overflow-hidden border-input rounded-lg`}
      id={type === "search-input" ? "search-div" : iconSrc}
    >
      <div
        className="flex items-center w-full h-full relative"
        onClick={type !== "search-input" ? clickSelect : (a) => a}
      >
        <img src={iconSrc} className={`${props.iconCls}  md:ml-6`} />
        {type !== "search-input" ? (
          <input
            value={defaultValue}
            readOnly={true}
            className={`${props.textCls} h-full ml-4 flex-grow w-full type-select-text-style`}
          />
        ) : (
          <input
            id="search-input"
            type="text"
            className={`${props.textCls}  ml-4 flex-grow w-full placeholder-gray-500 type-select-text-style`}
            value={props.value}
            placeholder={defaultValue}
            onChange={searchOption}
          />
        )}
        {type !== "search-input" ? (
          <svg
            ref={arrowRef}
            className="cursor-pointer absolute right-1"
            width="38"
            height="9"
            viewBox="0 0 38 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.1563 1.68354C12.8038 2.04504 12.8038 2.62162 13.1563 2.98312L18.3067 8.26564C18.6992 8.66817 19.3462 8.66817 19.7387 8.26564L24.8892 2.98313C25.2416 2.62163 25.2416 2.04504 24.8892 1.68354C24.5238 1.30886 23.9216 1.30886 23.5563 1.68354L19.3807 5.96615C19.1845 6.16742 18.8609 6.16742 18.6647 5.96615L14.4892 1.68354C14.1238 1.30886 13.5216 1.30886 13.1563 1.68354Z"
              fill="#0E0E0E"
              fillOpacity="0.6"
            />
          </svg>
        ) : (
          <></>
        )}
      </div>
      <ul ref={optionsRef} className="hidden">
        {props.options &&
          props.options.map((o, ind) => (
            <li
              key={ind}
              onMouseDown={() => props.selectOption(o)}
              className="flex mb-6  cursor-pointer type-op-text-style"
            >
              <img
                src={o.incident.iconUrl}
                className={`w-6 h-6 md:ml-6 mr-5 ${props.subIconCls}`}
              />
              {o.incident.displayName || o.incident.name}
            </li>
          ))}
      </ul>
    </div>
  );
}
