import { EMBED_LOCATION } from "../constants";

export function getUrlQueryObject(queryArr) {
  const urlParam = new URLSearchParams(window.location.search);
  const obj = {};
  queryArr.forEach((q) => {
    if (urlParam.get(q)) obj[q] = urlParam.get(q);
  });
  return obj;
}

export function isEmbedLocation() {
  const urlParam = new URLSearchParams(window.location.search);
  return urlParam.get(EMBED_LOCATION[0]) && urlParam.get(EMBED_LOCATION[1]);
}
