const urlParam = new URLSearchParams(window.location.search);
const embeded = urlParam.get("mode") === "embed";
const embedCode = urlParam.get("embedCode");
const categoryOrderReversed = urlParam.get("categoryOrder") === "reverse";

export const useURLParam = () => ({
  embeded,
  embedCode,
  categoryOrderReversed,
});
