import './NewInputField.css';
export default function NewInputField(props) {
  const v = () => {}
  return (
    <div className='newInput'>
      {props.labelName ? (
        <div className="newInputLabel">
          {props.labelName}{" "}
          {props.required ? (
            <span className="newInputLabelAsterisk">*</span>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {
        props.readonly ? (
          <div className='newInputReadOnly'>
            <img src={props.iconUrl} style={{width: props.iconWidth || 20, height: props.iconHeight || 20}}/>
            <div>{props.value}</div>
          </div>
        ) : (
          <input
            className='newInputBody'
            type="text"
            required={props.required}
            onChange={props.onChange}
            name={props.labelName}
            value={props.value}
            onBlur={props.onBlur || v}
          />
        )
      }
    </div>
  )
}