export default function Caption({ children }) {
  return (
    <div
      style={{ lineHeight: "initial" }}
      className="w-80 md:w-auto font-bold  text-heading-color  text-center text-lg md:text-2xl mt-8 md:mt-12 "
    >
      {children}
    </div>
  );
}
