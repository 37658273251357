import "./App.css";
import Header from "./Header";
import path from "path";
import { Tab, Menu } from "semantic-ui-react";
import IncidentLocation from "./IncidentLocation";
import IncidentDetails from "./IncidentDetails";
import ReportSummary from "./ReportSummary";
import IncidentType from "./IncidentType";
import CallPage from './CallPage';
import "semantic-ui-css/semantic.min.css";
import { useEffect, useState } from "react";
import ThankYou from "./compnents/ThankYou";
import { EmbededFooter, Footer, QrFooter } from "./Footer";
import { useURLParam } from "./hooks/useURLParam";
import { IsIE11 } from "./compnents/Utils";
import useModal from "./hooks/useModal";
import InfoModal from "./compnents/InfoModal";
import { traceIncidentLocationLaunch } from "./utils/tracer";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
  api_host: process.env.REACT_APP_MIXPANEL_API_HOST,
  ignore_dnt: true,
});

const locIncidentTypeMapping = {};

function App() {
  const defaultLoc = {
    lat: -25,
    lng: 133,
    address: "Australia",
  };
  const { embeded } = useURLParam();
  const [step, setStep] = useState(
    window.location.pathname.indexOf("/qr/") === 0 ? 1 : 0
  );
  const [activeIndex, setActiveIndex] = useState(
    window.location.pathname.indexOf("/qr/") === 0 ? 1 : 0
  );
  const [visible, content, showModal, hideModal] = useModal();
  const [finished, setFinished] = useState(false);
  const [loc, setLoc] = useState(defaultLoc);
  const [locPhoto, setLocPhoto] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState({});
  const [images, setImages] = useState([]);
  const [locFromImage, setLocFromImage] = useState(false);
  const [incidentDetails, setIncidentDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [uploadImages, setUploadImages] = useState([]);
  const [qrCode, setQrCode] = useState("");
  const [layoutInfo, setLayoutInfo] = useState();
  // same in IncidentType.js
  const [qrStatus, setQrStatus] = useState(
    window.location.pathname.indexOf("/qr/") === 0 ? 1 : 0
  );

  const [callStatus , setCallStatus] = useState(0);
  const [callInfo, setCallInfo] = useState();

  useEffect(() => {
    if (IsIE11()) {
      showModal(
        "You are using a browser that is not supported by Snap Send Solve. Please consider changing your browser."
      );
      return;
    }

    traceIncidentLocationLaunch();
  }, []);

  // useEffect(() => {
  //   if (!callInfo || !(callInfo && (callInfo.isCallOnly || callInfo.isCallRecommend))) {
  //     if (step === 2) setActiveIndex(2);
  //   };
  // }, [step, callInfo]);

  const setQrLayoutInfo = (data) => setLayoutInfo(data);

  if (IsIE11()) {
    return (
      <InfoModal
        visible={visible}
        content={content}
        hide={() => {}}
        locIcon={false}
      />
    );
  }
  // console.log('----qrStatus----', qrStatus)
  // console.log('----layoutInfo----', layoutInfo)
  const mapTab = qrStatus || callStatus
    ? {
        menuItem: null,
        render: () => null,
      }
    : {
        menuItem: (
          <Menu.Item key="incident-loc" className="tab-menu ">
            <svg
              className="md:hidden"
              width="14"
              height="20"
              viewBox="0 0 14 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={activeIndex === 0 ? "fill-tab-icon" : ""}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 7.26465C0 3.39465 3.13 0.264648 7 0.264648C10.87 0.264648 14 3.39465 14 7.26465C14 11.4346 9.58 17.1846 7.77 19.3746C7.37 19.8546 6.64 19.8546 6.24 19.3746C4.42 17.1846 0 11.4346 0 7.26465ZM4.5 7.26465C4.5 8.64465 5.62 9.76465 7 9.76465C8.38 9.76465 9.5 8.64465 9.5 7.26465C9.5 5.88465 8.38 4.76465 7 4.76465C5.62 4.76465 4.5 5.88465 4.5 7.26465Z"
                fill="#B3B3B3"
              />
            </svg>
            <span className="hidden md:inline-block">
              1. Incident Location{" "}
            </span>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false}>
            <IncidentLocation
              loc={loc}
              setLocFromImage={setLocFromImage}
              disableJump={() => setStep(0)}
              enableJump={() => setStep(1)}
              setLoc={(loc) => {
                if (loc.lat && loc.lat !== defaultLoc.lat && step === 0)
                  setStep(1);
                setLoc((prev) => {
                  if (
                    loc.address !== prev.address &&
                    prev.address !== defaultLoc.address
                  ) {
                    setSearchInputValue({});
                    setLocFromImage((locFromImage) => {
                      if (locFromImage) setImages((prev) => prev.slice(0, 1));
                      else setImages([]);
                      return locFromImage;
                    });
                    setIncidentDetails({});
                    setUserDetails({});
                    setUploadImages([]);
                  }
                  return Object.assign(Object.assign({}, prev), loc);
                });
              }}
              defaultLoc={defaultLoc}
              images={locPhoto}
              setImages={(locPhoto) => {
                if (locPhoto[0]) {
                  setImages((images) => {
                    for (let i in images) {
                      if (images[i].name === locPhoto[0].name) return images;
                    }
                    return [...locPhoto, ...images];
                  });
                }
                setLocPhoto(locPhoto);
              }}
              nextTab={() => setActiveIndex(1)}
            />
          </Tab.Pane>
        ),
      };


  return (
    <>
      {embeded ? (
        <></>
      ) : (
        <Header clickHeader={() => setActiveIndex(0)} layoutInfo={layoutInfo} />
      )}
      <div
        id="container"
        className={`${embeded ? "pb-20 pt-8 md:pt-0" : "pb-28 pt-0"}`}
        style={{ paddingBottom: qrStatus ? "0px" : "auto" }}
      >
        {finished ? (
          <ThankYou
            incidentType={searchInputValue.displayName || searchInputValue.name}
            authority={searchInputValue.authority.fullName}
          />
        ) : callStatus ? <CallPage searchInputValue={searchInputValue} callInfo={callInfo} goNextForm={() => {
          setActiveIndex(2);
          setStep(2);
          setCallStatus(0)
        }} goPreList={() => {
          setActiveIndex(1);
          if (!callInfo || !(callInfo && (callInfo.isCallOnly || callInfo.isCallRecommend))) {
            setStep(1);
          }
          setCallStatus(0);
        }}/> : (
          <Tab
            onTabChange={(e, t) => {
              if (step >= t.activeIndex) setActiveIndex(t.activeIndex);
            }}
            className={`flex flex-col justify-center items-center ${
              qrStatus && qrStatus !== 3 && step === 1 ? "no-tabs" : ""
            }`}
            activeIndex={activeIndex}
            menu={{ borderless: true, attached: false, tabular: false }}
            panes={[
              mapTab,
              {
                menuItem: (
                  <Menu.Item key="incident-type" className="tab-menu">
                    <svg
                      className="md:hidden"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={activeIndex === 1 ? "fill-tab-icon" : ""}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.125 4.65C5.7581 4.65 4.65 5.7581 4.65 7.125C4.65 8.4919 5.7581 9.6 7.125 9.6C8.4919 9.6 9.6 8.4919 9.6 7.125C9.6 5.7581 8.4919 4.65 7.125 4.65ZM2.85 7.125C2.85 4.76398 4.76398 2.85 7.125 2.85C9.48602 2.85 11.4 4.76398 11.4 7.125C11.4 9.48602 9.48602 11.4 7.125 11.4C4.76398 11.4 2.85 9.48602 2.85 7.125ZM12.6 7.5C12.6 7.00294 13.0029 6.6 13.5 6.6H20.25C20.7471 6.6 21.15 7.00294 21.15 7.5V14.25C21.15 14.7471 20.7471 15.15 20.25 15.15H13.5C13.0029 15.15 12.6 14.7471 12.6 14.25V7.5ZM14.4 8.4V13.35H19.35V8.4H14.4ZM9.78677 13.0629C9.62804 12.7772 9.32688 12.6 9.00003 12.6C8.67318 12.6 8.37202 12.7772 8.21329 13.0629L4.46326 19.8129C4.30839 20.0917 4.3126 20.4316 4.47432 20.7064C4.63603 20.9813 4.93111 21.15 5.25 21.15H12.75C13.0689 21.15 13.364 20.9813 13.5257 20.7064C13.6874 20.4316 13.6916 20.0917 13.5367 19.8129L9.78677 13.0629ZM11.2204 19.35H6.77957L9.00002 15.3532L11.2204 19.35Z"
                        fill="#B3B3B3"
                      />
                      <circle
                        className={activeIndex === 1 ? "fill-tab-icon" : ""}
                        cx="7"
                        cy="7"
                        r="3"
                        fill="#B3B3B3"
                      />
                      <rect
                        className={activeIndex === 1 ? "fill-tab-icon" : ""}
                        x="14"
                        y="8"
                        width="6"
                        height="6"
                        fill="#B3B3B3"
                      />
                      <path
                        className={activeIndex === 1 ? "fill-tab-icon" : ""}
                        d="M9 15L11.5981 19.5H6.40192L9 15Z"
                        fill="#B3B3B3"
                      />
                    </svg>
                    <span className="hidden md:inline-block">
                      {qrStatus ? 1 : 2}. Incident Type
                    </span>
                  </Menu.Item>
                ),
                render: () => (
                  <Tab.Pane attached={false}>
                    <IncidentType
                      searchInputValue={searchInputValue}
                      returnBack={() => setActiveIndex(0)}
                      setSearchInputValue={(s) => {
                        setSearchInputValue((prev) => {
                          if (
                            prev.incident &&
                            s.incident &&
                            prev.incident.id !== s.incident.id
                          ) {
                            if (locFromImage)
                              setImages((prev) => prev.slice(0, 1));
                            else setImages([]);
                            setIncidentDetails({});
                            setUserDetails({});
                            setUploadImages([]);
                          }
                          return s;
                        });
                        if (s.callScreen && (s.callScreen.isCallOnly || s.callScreen.isCallRecommend)) {
                          setCallInfo({...s.callScreen});
                          setCallStatus(1);
                        } else {
                          setCallInfo(undefined);
                          setStep(2);
                          setActiveIndex(2);
                        }
                      }}
                      incidents={
                        `${loc.lat}-${loc.lng}` in locIncidentTypeMapping
                          ? locIncidentTypeMapping[`${loc.lat}-${loc.lng}`]
                          : []
                      }
                      setIncidents={(incidents) => {
                        locIncidentTypeMapping[`${loc.lat}-${loc.lng}`] =
                          incidents;
                      }}
                      loc={loc}
                      setQrCode={setQrCode}
                      qrStatusDone={setQrStatus}
                      setQrLayoutInfo={setQrLayoutInfo}
                    />
                  </Tab.Pane>
                ),
              },
              {
                menuItem: (
                  <Menu.Item key="incident-detail" className="tab-menu">
                    <svg
                      className="md:hidden"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={activeIndex === 2 ? "fill-tab-icon" : ""}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20 5H16.83L15.59 3.65C15.22 3.24 14.68 3 14.12 3H9.88C9.32 3 8.78 3.24 8.4 3.65L7.17 5H4C2.9 5 2 5.9 2 7V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V7C22 5.9 21.1 5 20 5ZM12 10C10.3431 10 9 11.3431 9 13C9 14.6569 10.3431 16 12 16C13.6569 16 15 14.6569 15 13C15 11.3431 13.6569 10 12 10ZM7 13C7 15.76 9.24 18 12 18C14.76 18 17 15.76 17 13C17 10.24 14.76 8 12 8C9.24 8 7 10.24 7 13Z"
                        fill="#B3B3B3"
                      />
                    </svg>
                    <span className="hidden md:inline-block">
                      {qrStatus ? 2 : 3}. Details of Incident
                    </span>
                  </Menu.Item>
                ),
                render: () => (
                  <Tab.Pane attached={false}>
                    <IncidentDetails
                      images={images}
                      setImages={setImages}
                      setLocPhoto={setLocPhoto}
                      locPhoto={locPhoto}
                      incidentType={searchInputValue}
                      incidentDetails={incidentDetails}
                      setIncidentDetails={(p) => {
                        setStep(3);
                        setIncidentDetails(p);
                      }}
                      setUploadImages={(image) => {
                        setUploadImages((prev) => {
                          if (
                            image.key &&
                            prev
                              .map((p) => path.basename(p.key))
                              .indexOf(path.basename(image.key)) < 0
                          ) {
                            return [image, ...prev];
                          } else {
                            for (let i in prev) {
                              if (prev[i].key.indexOf(image) >= 0) {
                                prev.splice(i, 1);
                                return prev.slice();
                              }
                            }
                            return prev;
                          }
                        });
                      }}
                      nextTab={() => setActiveIndex(3)}
                    />
                  </Tab.Pane>
                ),
              },
              {
                menuItem: (
                  <Menu.Item key="report-summary" className="tab-menu">
                    <svg
                      className="md:hidden"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={activeIndex === 3 ? "fill-tab-icon" : ""}
                        d="M3.67124 20.3995L21.1212 12.9195C21.9312 12.5695 21.9312 11.4295 21.1212 11.0795L3.67124 3.59953C3.01124 3.30953 2.28124 3.79953 2.28124 4.50953L2.27124 9.11953C2.27124 9.61953 2.64124 10.0495 3.14124 10.1095L17.2712 11.9995L3.14124 13.8795C2.64124 13.9495 2.27124 14.3795 2.27124 14.8795L2.28124 19.4895C2.28124 20.1995 3.01124 20.6895 3.67124 20.3995Z"
                        fill="#B3B3B3"
                      />
                    </svg>

                    <span className="hidden md:inline-block">
                      {qrStatus ? 3 : 4}. Report Summary{" "}
                    </span>
                  </Menu.Item>
                ),
                render: () => (
                  <Tab.Pane attached={false}>
                    <ReportSummary
                      loc={loc}
                      userDetails={userDetails}
                      setUserDetails={setUserDetails}
                      incidentDetails={incidentDetails}
                      incidentType={searchInputValue}
                      uploadImages={uploadImages}
                      setFinished={setFinished}
                      qrCode={qrCode}
                      layoutInfo={layoutInfo}
                    />
                  </Tab.Pane>
                ),
              },
            ]}
          />
        )}
      </div>
      {qrStatus === 3 ? (
        <QrFooter />
      ) : qrStatus ? null : embeded ? (
        <EmbededFooter />
      ) : (
        <Footer />
      )}
    </>
  );
}

export default App;
