import { useEffect, useState } from "react";
import { useURLParam } from "../hooks/useURLParam";
import './ThankYou.css';

export default function ThankYou({ authority, incidentType }) {
  const { embeded } = useURLParam();
  const [qrStatus, setQrStatus] = useState(
    window.location.pathname.indexOf("/qr/") === 0 ? 1 : 0
  );
  // useEffect(() => {
  //   document.querySelector("#root").style.height = "100%";
  //   if (embeded) {
  //     document.querySelector("#container").style.height = "100%";
  //   } else {
  //     document.querySelector("#container").style.height = "calc(100% - 80px)";
  //   }
  // }, []);
  const appleStoreLink = qrStatus
    ? "https://apps.apple.com/app/apple-store/id377854149?pt=2123356&ct=qr-flow&mt=8"
    : "https://apps.apple.com/app/apple-store/id377854149?pt=2123356&ct=website-webform&mt=8";
  const googleStoreLink = qrStatus
    ? "https://play.google.com/store/apps/details?id=com.outware.snapsendsolve&referrer=utm_source%3Dqr-flow"
    : "https://play.google.com/store/apps/details?id=com.outware.snapsendsolve&referrer=utm_source%3Dwebsite-webform";

  return (
    <div className="flex-layout h-full md:justify-center">
      <div className="flex flex-col items-center text-center text-lg mt-20 font-field flex-grow font-medium max-w-xs pl-4 pr-4 md:flex-grow-0 md:mb-0 md:max-w-max">
        <img src="/imgs/paper-plane.png" className="w-thankyou h-thankyou" />
        <h1 className="report-sent-tips">Report sent!</h1>
        <p className="auth-contact-tips">
          {authority} may contact you directly with any updates.
        </p>
      </div>
      <div className="app-store-section">
        <img src="/logo192.png" alt="App Logo" className="m-auto w-16 h-16" />
        <p className="mt-2 mb-2">
          Download <strong>Snap Send Solve</strong> to get updates on future
          reports and report other incidents in your local community.{" "}
        </p>
        <div className="inline-flex">
          <a href={appleStoreLink} target="_blank" className="mr-auto mr-1">
            <img
              src="/imgs/apple-app-store-icon.png"
              alt="Appstore button"
              className="icon-apple-app-store"
            />
          </a>
          <a href={googleStoreLink} target="_blank" className="mr-auto">
            <img
              src="/imgs/google-play-store-icon.png"
              alt="Google Play button"
              className="icon-google-play-store"
            />
          </a>
        </div>
      </div>
      {qrStatus ? null : (
        <button
          className="btn-default mb-0 md:w-64"
          onClick={() => window.location.reload()}
        >
          Send another report
        </button>
      )}
    </div>
  );
}
