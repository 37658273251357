// import GoogleSign from "./compnents/GoogleSignin";
// import AppleSign from "./compnents/AppleSignin";
import { useEffect, useState } from "react";

export default function Header(props) {
  const [qrStatus, setQrStatus] = useState(
    window.location.pathname.indexOf("/qr/") === 0 ? 1 : 0
  );
  useEffect(() => {
    if (!qrStatus) {
      let head = document.getElementsByTagName("head")[0];
      head.innerHTML += `
        <meta name="smartbanner:title" content="Snap Send Solve">
        <meta name="smartbanner:price" content="FREE">
        <meta name="smartbanner:author" content="Snap Send Solve">
        <meta name="smartbanner:price-suffix-google" content=" - In Google Play">
        <meta name="smartbanner:icon-google" content="logo192.png">
        <meta name="smartbanner:button" content="VIEW">
        <meta name="smartbanner:button-url-google" content="https://play.google.com/store/apps/details?id=com.outware.snapsendsolve">
        <meta name="smartbanner:hide-ttl" content="86400000">
        <meta name="smartbanner:enabled-platforms" content="android,ios">
        <meta name="apple-itunes-app" content="app-id=377854149" >
        <meta name="smartbanner:close-label" content="Close">`;
    }
  }, []);
  let logo = undefined;
  let primaryColour = undefined;
  if (props.layoutInfo && props.layoutInfo.authority) {
    const { logo: l, primaryColour: p } = props.layoutInfo.authority;
    logo = l;
    primaryColour = p;
  }
  return (
    <div
      style={{
        height: "64px",
        backgroundColor: qrStatus && primaryColour ? primaryColour : "#06A8F4",
      }}
      onClick={props.clickHeader}
      className={`${props.className} 
      cursor-pointer w-full h-logo-head md:pl-8 flex md:justify-start justify-center items-center bg-default-blue`}
    >
      {/*<div className="hidden apple-sign-in  right-apple md:right-apple-md ">*/}
      {/*  <AppleSign />*/}
      {/*</div>*/}
      {/*<div className="hidden google-sign-in">*/}
      {/*  <GoogleSign />*/}
      {/*</div>*/}
      {qrStatus && logo ? (
        <img
          src={logo}
          style={{ width: "64px", height: "64px" }}
          alt="logo"
        ></img>
      ) : (
        <svg
          width="169"
          height="19"
          viewBox="0 0 169 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4393 10.9468C10.4393 8.59957 8.94795 7.56763 6.99928 6.65708C6.38287 6.3738 4.45409 5.6049 4.09617 4.99787C3.97687 4.81576 3.95698 4.59318 3.95698 4.37061C3.95698 3.66241 4.59328 3.19702 5.24946 3.19702C6.20391 3.19702 6.48229 3.82428 6.6016 4.65389H10.32C10.2802 3.4803 10.1012 2.65069 9.34563 1.69968C8.41107 0.546325 6.80044 0 5.36877 0C2.86334 0 0.218728 1.61874 0.218728 4.41108C0.218728 6.75825 1.9089 7.89137 3.81779 8.70075C4.25525 8.88285 4.6927 9.06496 5.13016 9.26731C5.90565 9.61129 6.70102 9.93504 6.70102 10.9063C6.70102 11.7157 6.04484 12.3429 5.26935 12.3429C4.17571 12.3429 3.73825 11.2705 3.73825 10.2993H0V10.7849C0 13.9819 2.34635 15.5399 5.30912 15.5399C8.21223 15.5399 10.4393 14.0831 10.4393 10.9468Z"
            fill="white"
          />
          <path
            d="M22.315 15.2567V8.23536C22.315 6.92013 22.0963 5.50373 21.0424 4.61342C20.3266 4.00639 19.1534 3.64217 18.2189 3.64217C16.8866 3.64217 15.9123 4.06709 15.2163 5.22045H15.1766V4.02662H12.0945V15.2567H15.3953V9.1459C15.3953 7.81044 15.7532 6.79872 17.2843 6.79872C18.7756 6.79872 19.0142 7.87114 19.0142 9.12567V15.2567H22.315Z"
            fill="white"
          />
          <path
            d="M36.2507 15.2567V4.02662H32.9499V5.22045H32.9101C32.2539 4.10756 30.9018 3.64217 29.6889 3.64217C26.3682 3.64217 24.1411 6.35357 24.1411 9.63152C24.1411 12.9702 26.2886 15.6411 29.6889 15.6411C30.8819 15.6411 32.3136 15.196 32.9101 14.0426H32.9499V15.2567H36.2507ZM32.9499 9.63152C32.9499 11.2503 31.7966 12.5048 30.186 12.5048C28.635 12.5048 27.4419 11.23 27.4419 9.67199C27.4419 8.07348 28.5555 6.77849 30.1661 6.77849C31.7767 6.77849 32.9499 7.99255 32.9499 9.63152Z"
            fill="white"
          />
          <path
            d="M51.0373 9.75293C51.0373 6.49521 49.0688 3.64217 45.6487 3.64217C44.2965 3.64217 43.0637 4.16826 42.2087 5.26092H42.1689H42.149V4.02662H39.0471V19H42.3479V14.2449C43.2427 15.2364 44.3562 15.6411 45.6686 15.6411C48.8898 15.6411 51.0373 12.8892 51.0373 9.75293ZM47.7365 9.65176C47.7365 11.23 46.5435 12.5048 44.9726 12.5048C43.4017 12.5048 42.2087 11.23 42.2087 9.65176C42.2087 8.05325 43.3819 6.77849 44.9726 6.77849C46.5634 6.77849 47.7365 8.05325 47.7365 9.65176Z"
            fill="white"
          />
          <path
            d="M68.7912 10.9468C68.7912 8.59957 67.2999 7.56763 65.3512 6.65708C64.7348 6.3738 62.806 5.6049 62.4481 4.99787C62.3288 4.81576 62.3089 4.59318 62.3089 4.37061C62.3089 3.66241 62.9452 3.19702 63.6014 3.19702C64.5559 3.19702 64.8343 3.82428 64.9536 4.65389H68.6719C68.6322 3.4803 68.4532 2.65069 67.6976 1.69968C66.763 0.546325 65.1524 0 63.7207 0C61.2153 0 58.5707 1.61874 58.5707 4.41108C58.5707 6.75825 60.2609 7.89137 62.1698 8.70075C62.6072 8.88285 63.0447 9.06496 63.4821 9.26731C64.2576 9.61129 65.053 9.93504 65.053 10.9063C65.053 11.7157 64.3968 12.3429 63.6213 12.3429C62.5277 12.3429 62.0902 11.2705 62.0902 10.2993H58.352V10.7849C58.352 13.9819 60.6983 15.5399 63.6611 15.5399C66.5642 15.5399 68.7912 14.0831 68.7912 10.9468Z"
            fill="white"
          />
          <path
            d="M81.7536 9.77316C81.7536 6.39404 79.3079 3.64217 75.9275 3.64217C72.6864 3.64217 70.1412 6.41427 70.1412 9.67199C70.1412 12.9499 72.7659 15.6209 75.9872 15.6209C78.4727 15.6209 80.4214 13.9819 81.3758 11.7359H77.9955C77.4586 12.4239 76.882 12.7881 75.9872 12.7881C74.6947 12.7881 73.6011 11.9989 73.4022 10.6635H81.6741C81.7337 10.36 81.7536 10.0767 81.7536 9.77316ZM78.4528 8.39723H73.442C73.7005 7.18317 74.7543 6.47497 75.9474 6.47497C77.1405 6.47497 78.1943 7.18317 78.4528 8.39723Z"
            fill="white"
          />
          <path
            d="M93.8132 15.2567V8.23536C93.8132 6.92013 93.5944 5.50373 92.5406 4.61342C91.8247 4.00639 90.6516 3.64217 89.717 3.64217C88.3848 3.64217 87.4104 4.06709 86.7145 5.22045H86.6747V4.02662H83.5926V15.2567H86.8934V9.1459C86.8934 7.81044 87.2513 6.79872 88.7824 6.79872C90.2738 6.79872 90.5124 7.87114 90.5124 9.12567V15.2567H93.8132Z"
            fill="white"
          />
          <path
            d="M107.649 15.2567V0.28328H104.349V4.97764C103.454 4.04686 102.38 3.64217 101.107 3.64217C97.7868 3.64217 95.6393 6.35357 95.6393 9.61129C95.6393 12.9297 97.7868 15.6209 101.167 15.6209C102.46 15.6209 103.772 15.2364 104.468 14.0426H104.508V15.2567H107.649ZM104.528 9.59105C104.528 11.2503 103.354 12.4846 101.704 12.4846C100.054 12.4846 98.9401 11.23 98.9401 9.59105C98.9401 7.95208 100.153 6.77849 101.744 6.77849C103.335 6.77849 104.528 7.97231 104.528 9.59105Z"
            fill="white"
          />
          <path
            d="M125.92 10.9468C125.92 8.59957 124.429 7.56763 122.48 6.65708C121.863 6.3738 119.935 5.6049 119.577 4.99787C119.457 4.81576 119.438 4.59318 119.438 4.37061C119.438 3.66241 120.074 3.19702 120.73 3.19702C121.684 3.19702 121.963 3.82428 122.082 4.65389H125.801C125.761 3.4803 125.582 2.65069 124.826 1.69968C123.892 0.546325 122.281 0 120.849 0C118.344 0 115.699 1.61874 115.699 4.41108C115.699 6.75825 117.389 7.89137 119.298 8.70075C119.736 8.88285 120.173 9.06496 120.611 9.26731C121.386 9.61129 122.182 9.93504 122.182 10.9063C122.182 11.7157 121.525 12.3429 120.75 12.3429C119.656 12.3429 119.219 11.2705 119.219 10.2993H115.481V10.7849C115.481 13.9819 117.827 15.5399 120.79 15.5399C123.693 15.5399 125.92 14.0831 125.92 10.9468Z"
            fill="white"
          />
          <path
            d="M139.24 9.63152C139.24 6.19169 136.675 3.66241 133.295 3.66241C129.914 3.66241 127.349 6.19169 127.349 9.63152C127.349 13.0714 129.914 15.6006 133.295 15.6006C136.675 15.6006 139.24 13.0714 139.24 9.63152ZM135.939 9.63152C135.939 11.1491 134.826 12.4643 133.295 12.4643C131.764 12.4643 130.65 11.1491 130.65 9.63152C130.65 8.11395 131.764 6.79872 133.295 6.79872C134.826 6.79872 135.939 8.11395 135.939 9.63152Z"
            fill="white"
          />
          <path
            d="M144.216 15.2567V0.28328H140.915V15.2567H144.216Z"
            fill="white"
          />
          <path
            d="M156.845 4.02662H153.425L151.318 11.0277H151.278L149.17 4.02662H145.75L149.707 15.2567H152.869L156.845 4.02662Z"
            fill="white"
          />
          <path
            d="M169 9.77316C169 6.39404 166.554 3.64217 163.174 3.64217C159.933 3.64217 157.388 6.41427 157.388 9.67199C157.388 12.9499 160.012 15.6209 163.234 15.6209C165.719 15.6209 167.668 13.9819 168.622 11.7359H165.242C164.705 12.4239 164.128 12.7881 163.234 12.7881C161.941 12.7881 160.847 11.9989 160.649 10.6635H168.92C168.98 10.36 169 10.0767 169 9.77316ZM165.699 8.39723H160.688C160.947 7.18317 162.001 6.47497 163.194 6.47497C164.387 6.47497 165.441 7.18317 165.699 8.39723Z"
            fill="white"
          />
        </svg>
      )}
    </div>
  );
}
