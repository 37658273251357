import React, { useState, useEffect, useRef } from "react";

import QrStatus from "./compnents/QrStatus";
import Caption from "./compnents/Caption";
import DropDown from "./compnents/DropDown";
import InputField from "./compnents/InputField";
import Loading from "./compnents/Loading";
import InfoModal from "./compnents/InfoModal";
import { useURLParam } from "./hooks/useURLParam";
import useModal from "./hooks/useModal";
import {
  traceIncidentTypeLaunch,
  traceIncidentTypeSelect,
  traceIncidentTypeScanQrCode,
  traceStartNewReport
} from "./utils/tracer";
import IncidentTypeTip from "./compnents/IncidentTypeTip";

function sortCategories(a, b) {
  let nameA = a.name || a.displayName;
  let nameB = b.name || b.displayName;
  let orderA = a.sortOrder;
  let orderB = b.sortOrder;
  nameA = nameA.toUpperCase();
  nameB = nameB.toUpperCase();
  if (orderA < orderB) {
    return -1;
  }
  if (orderA > orderB) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

function sortIncidents(a, b) {
  let nameA = a.incident.name || a.incident.displayName;
  let nameB = b.incident.name || b.incident.displayName;
  let customOrderA = a.incident.sortOrder;
  let customOrderB = b.incident.sortOrder;
  nameA = nameA.toUpperCase();
  nameB = nameB.toUpperCase();
  if (customOrderA < customOrderB) {
    return -1;
  }
  if (customOrderA > customOrderB) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export default function IncidentType({
  nextTab,
  loc,
  incidents,
  returnBack,
  setIncidents,
  searchInputValue,
  setSearchInputValue,
  setQrCode,
  qrStatusDone,
  setQrLayoutInfo,
}) {
  const { embedCode, categoryOrderReversed } = useURLParam();
  const selectOpContainerRef = useRef();
  const [categories, setCategories] = useState([]);
  const [incidentCategories, setIncidentCategories] = useState({});
  const [incidentNoCategories, setIncidentNoCategories] = useState([]);
  const [showLoading, setShowLoading] = useState(incidents.length === 0);
  const [visible, content, showModal, hideModal] = useModal();
  // when page from a QR , status 1: qr scanning, status 2: qr success tip, status 3: render incident
  const [qrStatus, setQrStatus] = useState(
    window.location.pathname.indexOf("/qr/") === 0 ? 1 : 0
  );
  const [layoutInfo, setLayoutInfo] = useState();

  const selectOption = (incident) => {
    if (qrStatus) {
      // const qrCode = window.location.pathname.split("/qr/")[1].split("/")[0];
      const qrCode = encodeURI(window.location.pathname);
      setQrCode(qrCode);
    }
    setSearchInputValue(incident);
    traceIncidentTypeSelect(
      incident.authority.id,
      incident.incident.name,
      qrStatus
    );
  };
  const parseIncidents = (incidents) => {
    let categories = {}; //incident with classification (最终的带有分类的incident)
    let incidentCategories = {};
    let incidentWithoutCategories = []; //unclassified incident (最终的没有分类的incident)
    for (let incident of incidents) {
      let { incident: incidentField } = incident;
      if (incidentField && !incidentField.iconUrl)
        incidentField.iconUrl = "/imgs/default-icon.png";
      if (incident.categories.length === 0)
        incidentWithoutCategories.push(incident);
      for (let category of incident.categories) {
        if (!category.iconUrl) category.iconUrl = "/imgs/default-icon.png";
        categories[category.name] = category;
        if (!(category.name in incidentCategories))
          incidentCategories[category.name] = [];
        incidentCategories[category.name].push(incident);
      }
    }
    categories = Object.values(categories);
    categories.sort(sortCategories);
    incidents.sort(sortIncidents);
    for(let key in incidentCategories) {
      incidentCategories[key].sort(sortIncidents)
    }
    incidentWithoutCategories.sort(sortIncidents);

    if (categoryOrderReversed) setCategories(categories.reverse());
    else setCategories(categories);

    setIncidentNoCategories(incidentWithoutCategories);
    setIncidentCategories(incidentCategories);
    // selectOpContainerRef.current.classList.remove("hidden");
  };
  useEffect(() => {
    traceIncidentTypeLaunch(qrStatus);
    if (incidents.length === 0) {
      let controller = new AbortController();
      let signal = controller.signal;
      let myHeaders = new Headers();
      myHeaders.append("x-api-key", process.env.REACT_APP_GATEWAY_KEY);
      let requestOptionsServices = {
        method: "GET",
        redirect: "follow",
        signal,
        headers: myHeaders,
      };
      let apiUrl = `${
        process.env.REACT_APP_SERVICE
      }/authorities/v2/incidentTypes?latitude=${loc.lat}&longitude=${loc.lng}${
        embedCode ? `&embedCode=${embedCode}` : ""
      }`;
      if (qrStatus) {
        // const qrCode = window.location.pathname.split("/qr/")[1].split("/")[0];
        const qrCode = encodeURI(window.location.pathname);
        apiUrl = `${process.env.REACT_APP_SERVICE}/authorities/v2/incidentTypes?qr=${qrCode}`;
      }
      fetch(apiUrl, requestOptionsServices)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((result) => {
          setShowLoading(false);
          if (qrStatus) {
            setQrStatus(2);
            if (result && result.length) {
              if (window.location.pathname.indexOf("/qr/") === 0) {
                traceStartNewReport({
                  medium: 'Web',
                  content: 'QR code',
                  asset_id: result[0].asset.id,
                  asset_name: result[0].asset.name,
                  asset_type: result[0].asset.type,

                })
              }
              setLayoutInfo(result[0]);
              // QR场景下外层样式变化，将相关信息传出
              // The Layout style changes in the QR scene, transmit the relevant data.
              setQrLayoutInfo(result[0]);
            }
            setTimeout(() => {
              setQrStatus(3);
              qrStatusDone(3);
            }, 800);
          }
          if (result.length === 0) {
            showModal(
              "This address is not in our service area. Please select a different address to continue"
            );
            return;
          }
          parseIncidents(result);
          setIncidents(result);
        })
        .catch((error) => {
          setShowLoading(false);
          showModal(
            "This address is not in our service area. Please select a different address to continue"
          );
        });
      if (qrStatus) traceIncidentTypeScanQrCode();
      return () => controller.abort();
    } else {
      parseIncidents(incidents);
    }
  }, []);

  return (
    <div className="flex items-center flex-col incident-type">
      <InfoModal
        content={content}
        visible={visible}
        hide={() => {
          hideModal();
          returnBack();
        }}
      />
      {!qrStatus || qrStatus === 3 ? (
        qrStatus && layoutInfo && layoutInfo.asset ? (
          <IncidentTypeTip
            asset={layoutInfo.asset}
            tip={"Select incident type"}
          />
        ) : (
          <Caption>What kind of incident are you reporting?</Caption>
        )
      ) : null}
      {showLoading && !qrStatus ? <Loading className="mt-80" /> : <></>}
      {!qrStatus || qrStatus === 3 || incidents.length ? (
        <React.Fragment>
          {qrStatus && layoutInfo && layoutInfo.asset ? null : (
            <DropDown
              options={incidents}
              setInputValue={setSearchInputValue}
              value={searchInputValue.displayName || searchInputValue.name}
              type="search-input"
              containerCls="mt-12 md:mt-14   w-full h-14"
              textCls="h-14"
              iconCls="ml-4 w-5 h-5"
              subIconCls="ml-4"
              defaultValue="Search for an incident"
              selectOption={selectOption}
              iconSrc="/imgs/Lookup.svg"
            />
          )}
          <div
            ref={selectOpContainerRef}
            className="md:border border-default-color  w-full  mt-6 mb-12  rounded-lg"
            style={{
              marginTop:
                qrStatus && layoutInfo && layoutInfo.asset ? "0px" : "20px",
            }}
          >
            {incidentNoCategories.map((incidentType, idx) => (
              <InputField
                key={`${
                  incidentType.incident.displayName ||
                  incidentType.incident.name
                }-${incidentType.incident.id}`}
                readonly={true}
                textCls={`text-lg ml-5 mr-4 pr-4 ${
                  idx === 0 ? "border-none" : "border-t"
                }  cursor-pointer`}
                containerCls="h-type-select"
                iconCls="md:ml-6"
                name={
                  incidentType.incident.displayName ||
                  incidentType.incident.name
                }
                value={
                  incidentType.incident.displayName ||
                  incidentType.incident.name
                }
                onClick={() => selectOption(incidentType)}
                iconUrl={incidentType.incident.iconUrl}
              />
            ))}
            {categories.map((category, idx) => (
              <DropDown
                key={category.name}
                selectOption={selectOption}
                containerCls="border-none h-type-select"
                textCls={`h-type-select ${
                  idx === 0 && incidentNoCategories.length === 0
                    ? "border-none"
                    : "border-t"
                } pr-6 mr-4  cursor-pointer`}
                iconCls="w-7 h-7"
                defaultValue={category.name}
                iconSrc={category.iconUrl}
                options={incidentCategories[category.name]}
              />
            ))}
          </div>
        </React.Fragment>
      ) : (
        <QrStatus status={qrStatus} />
      )}
    </div>
  );
}
