import "./IncidentTypeTip.css";

export default function incidentTypeTip({ asset, tip }) {
  const { iconUrl, name, type } = asset;

  return (
    <div className="incidentTypeTip">
      <div
        className="incidentTypeTip-tip-section"
        style={{ marginBottom: tip ? "24px" : "20px" }}
      >
        {iconUrl ? (
          <div className="logo-bg">
            <img src={iconUrl} alt="incident-type-logo" />
          </div>
        ) : null}
        <div className="incidentTypeTip-tip-section-right">
          <div className="incidentTypeTip-tip-section-type">{type || ""}</div>
          <div className="incidentTypeTip-tip-section-name">{name || ""}</div>
        </div>
      </div>
      {tip ? <div className="incidentTypeTip-tip-text">{tip}</div> : null}
    </div>
  );
}
