export default function NextBtn({nextTab = undefined, active = false}) {
  return (
    <button
      onClick={() => {
        if (active) nextTab();
      }}
      className=" border-lg text-lg bg-btn-blue font-medium font-field text-white w-96 h-12 mt-6 rounded-2xl
                  md:w-40 md:mt-12 md:rounded-xl "
      style={{background: active ? '#22548F' : 'rgba(14, 14, 14, 0.4)'}}            
    >
      Next
    </button>
  );
}
