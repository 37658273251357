import Caption from "./compnents/Caption";
import Map from "./compnents/Map";
import NextBtn from "./compnents/NextBtn";
import { useState, useEffect } from "react";
import EXIF from "exif-js";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import InfoModal from "./compnents/InfoModal";
import useModal from "./hooks/useModal";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import { IsIOS } from "./compnents/Utils";
import { traceIncidentLocationOfPhoto, traceStartNewReport } from "./utils/tracer";
import { useURLParam } from "./hooks/useURLParam";


// eslint-disable-next-line no-undef
registerPlugin(
  FilePondPluginImageResize,
  FilePondPluginImageCrop,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform
);

function parseGPS(exif) {
  let exifLong = exif.GPSLongitude;
  let exifLongRef = exif.GPSLongitudeRef;
  let exifLat = exif.GPSLatitude;
  let exifLatRef = exif.GPSLatitudeRef;
  let latitude,
    longitude = 0;
  if (exifLatRef === "S") {
    latitude = exifLat[0] * -1 + (exifLat[1] * -60 + exifLat[2] * -1) / 3600;
  } else {
    latitude = exifLat[0] + (exifLat[1] * 60 + exifLat[2]) / 3600;
  }
  if (exifLongRef === "W") {
    longitude =
      exifLong[0] * -1 + (exifLong[1] * -60 + exifLong[2] * -1) / 3600;
  } else {
    longitude = exifLong[0] + (exifLong[1] * 60 + exifLong[2]) / 3600;
  }
  return [longitude, latitude];
}
export default function IncidentLocation({
  nextTab,
  setLocFromImage,
  defaultLoc,
  loc,
  disableJump,
  enableJump,
  setLoc,
  images,
  setImages,
}) {
  const [showNext, setShowNext] = useState(false);
  const [visible, content, showModal, hideModal] = useModal();
  const { embedCode } = useURLParam();
  const updateLoc = (newLoc, source) => {
    if (newLoc.address !== defaultLoc.address) setShowNext(true);
    else setShowNext(false);
    if (source) setLocFromImage(true);
    else if (newLoc.lng && newLoc.lng !== loc.lng) setLocFromImage(false);
    setLoc(newLoc);
  };
  const handleUploadedPhoto = (photo) => {
    EXIF.getData(photo, () => {
      let exifData = EXIF.getAllTags(photo);
      if (exifData.GPSLongitude) {
        let [lng, lat] = parseGPS(exifData);
        updateLoc({ lat, lng }, true);
        traceIncidentLocationOfPhoto();
      } else {
        showModal(
          "Failed to retrieve location, please select location on the map"
        );
        setImages([]);
      }
    });
  };

  useEffect(() => {
    if (embedCode) {
      traceStartNewReport({
        medium: 'Web',
        content: 'Standard report',
        source: embedCode
      })
    } else {
      traceStartNewReport({
        medium: 'Web',
        content: 'Standard report'
      })
    }
  }, []);

  return (
    <div
      className=" container mx-auto flex flex-col justify-center items-center"
      style={{ maxWidth: "unset" }}
    >
      <InfoModal
        visible={visible}
        content={content}
        hide={hideModal}
        locIcon={true}
      />
      <Caption> Where's the incident located? </Caption>
      <Map
        apikey={process.env.REACT_APP_MAP_API_KEY}
        center={loc}
        disableNext={() => {
          setShowNext(false);
          disableJump();
        }}
        enableNext={() => {
          setShowNext(true);
          enableJump();
        }}
        defaultCenter={defaultLoc}
        setCenter={updateLoc}
        alert={() =>
          showModal(
            "Snap Send Solve uses your location to deliver information about council and services in the area. Turn on location services in your browser settings"
          )
        }
      />
      <div className={`mt-6 ${IsIOS() && "hidden"}`}>Or</div>
      <div
        className={`photo-loc w-5/12 mt-6 ${IsIOS() && "hidden"}`}
        style={{ minWidth: "173px" }}
      >
        <FilePond
          imageTransformOutputStripImageHead={false}
          allowImageCrop={true}
          allowImageResize={true}
          imageResizeTargetWidth={2048}
          imageResizeTargetHeight={2048}
          imageResizeMode={"cover"}
          imageResizeUpscale={false}
          acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
          imageCropAspectRatio="1:1"
          onpreparefile={(f, processed) => {
            if (images.length > 0 && images[0].name === f.file.name) return;
            handleUploadedPhoto(f.file);
            setImages([processed]);
          }}
          beforeRemoveFile={(file) => {
            console.log("remove");
            setImages([]);
          }}
          files={images}
          allowMultiple={false}
          labelIdle='<p class="mb-0">Use location of photo</p><p class="mb-0">Drag & Drop your files or <span class="filepond--label-action">Browse</span></p>'
        />
      </div>
      <div className={showNext ? "" : "hidden"}>
        <NextBtn nextTab={nextTab} active={showNext}/>
      </div>
    </div>
  );
}
