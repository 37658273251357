import mixpanel from "mixpanel-browser";
const MEDIUM_QR = "qr-code";

function getOrigin() {
  let origin =
    window.location.origin ||
    document.referrer ||
    window.location.ancestorOrigins[0];
  if (origin && origin !== "") {
    origin = origin.split("/")[2];
  }
  return origin;
}

export function traceIncidentLocationLaunch() {
  mixpanel.track("new_report_screen", {
    origin: getOrigin(),
  });
  window.gtag("event", "new_report_screen", {
    origin: getOrigin(),
  });
}

export function traceIncidentLocationActiveInput() {
  mixpanel.track("click_address_bar", {
    origin: getOrigin(),
  });
  window.gtag("event", "click_address_bar", {
    origin: getOrigin(),
  });
}

export function traceIncidentLocationOfPhoto() {
  mixpanel.track("use_location_from_gallery", {
    origin: getOrigin(),
  });
  window.gtag("event", "use_location_from_gallery", {
    origin: getOrigin(),
  });
}

export function traceIncidentLocationManualSelect() {
  mixpanel.track("select_location", {
    origin: getOrigin(),
  });
  window.gtag("event", "select_location", {
    origin: getOrigin(),
  });
}

export function traceIncidentTypeScanQrCode() {
  mixpanel.track("scan_qr_code", {
    origin: getOrigin(),
    medium: MEDIUM_QR,
  });
  window.gtag("event", "scan_qr_code", {
    origin: getOrigin(),
    medium: MEDIUM_QR,
  });
}

export function traceIncidentTypeLaunch(qr) {
  const obj = { origin: getOrigin() };
  if (qr) obj.medium = MEDIUM_QR;
  mixpanel.track("incident_list_screen", obj);
  window.gtag("event", "incident_list_screen", obj);
}

export function traceIncidentTypeSelect(authorityId, incidentType, qr) {
  const obj = {
    origin: getOrigin(),
    authority_id: authorityId,
    incident_type: incidentType,
  };
  if (qr) obj.medium = MEDIUM_QR;
  mixpanel.track("select_incident", obj);
  window.gtag("event", "select_incident", obj);
}

export function traceDetailsOfIncidentTakePhoto(authorityId, incidentType, qr) {
  const obj = {
    origin: getOrigin(),
    authority_id: authorityId,
    incident_type: incidentType,
  };
  if (qr) obj.medium = MEDIUM_QR;
  mixpanel.track("take_photo", obj);
  window.gtag("event", "take_photo", obj);
}

export function traceIncidentSummaryLaunch(authorityId, incidentType, qr) {
  const obj = {
    origin: getOrigin(),
    authority_id: authorityId,
    incident_type: incidentType,
  };
  if (qr) obj.medium = MEDIUM_QR;
  mixpanel.track("report_send_screen", obj);
  window.gtag("event", "report_send_screen", obj);
}

export function traceStartNewReport(obj) {
  const payload = { ...obj, origin: getOrigin() }
  mixpanel.track("start_new_report", payload);
  window.gtag("event", "start_new_report", payload);
}

export function traceIncidentSummaryReportSuccess(obj) {
  const payload = { ...obj, origin: getOrigin() }
  mixpanel.track("report_sent_success", payload);
  window.gtag("event", "report_sent_success", payload);
}

export function traceIncidentSummaryReportFail(authorityId, incidentType, qr) {
  const obj = {
    origin: getOrigin(),
    authority_id: authorityId,
    incident_type: incidentType,
  };
  if (qr) obj.medium = MEDIUM_QR;
  mixpanel.track("report_sent_failure", obj);
  window.gtag("event", "report_sent_failure", obj);
}

export function callNowScreen() {
  mixpanel.track("call_now_screen");
  window.gtag("event", "call_now_screen");
}

export function callNowTapped() {
  mixpanel.track("call_now_tapped");
  window.gtag("event", "call_now_tapped");
}

export function sendWithoutCallingTapped() {
  mixpanel.track("send_without_calling_tapped");
  window.gtag("event", "send_without_calling_tapped");
}
