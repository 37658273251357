import AWS from "aws-sdk";

export async function getS3Credentials() {
  let myHeaders = new Headers();
  myHeaders.append("x-api-key", process.env.REACT_APP_S3_API_KEY);
  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  let resp = await fetch(
    process.env.REACT_APP_S3_CREDENTIAL_ENDPOINT,
    requestOptions
  );
  return await resp.json();
}

export async function uploadPhotoToS3(file, credential) {
  if (!credential) return;
  AWS.config.update({
    sessionToken: credential.credentials.sessionToken,
    accessKeyId: credential.credentials.accessKeyId,
    secretAccessKey: credential.credentials.secretAccessKey,
  });
  const s3 = new AWS.S3();
  return await new Promise((resolve, reject) => {
    s3.putObject(
      {
        Body: file,
        Bucket: credential.bucket,
        Key: `${credential.folder}/${file.name}`,
      },
      (err, data) => {
        if (err) reject(err);
        else
          resolve(
            Object.assign(data, {
              bucket: credential.bucket,
              key: `${credential.folder}/${file.name}`,
            })
          );
      }
    );
  });
}
