import InputField from "./compnents/InputField";
import NewInputField from "./compnents/NewInputField";
import Caption from "./compnents/Caption";
import { useURLParam } from "./hooks/useURLParam";
import useModal from "./hooks/useModal";
import InfoModal from "./compnents/InfoModal";
import { useEffect, useMemo, useState } from "react";
import {
  traceIncidentSummaryLaunch,
  traceIncidentSummaryReportFail,
  traceIncidentSummaryReportSuccess,
} from "./utils/tracer";
import { Loader } from "semantic-ui-react";
import IncidentTypeTip from "./compnents/IncidentTypeTip";
import { validateEmail } from "./compnents/Utils";



export default function ReportSummary({
  loc,
  incidentType,
  incidentDetails,
  uploadImages,
  userDetails,
  setUserDetails,
  setFinished,
  qrCode,
  layoutInfo,
}) {
  const { embeded, embedCode } = useURLParam();
  const [submitting, setSubmitting] = useState(false);
  const [visible, content, showModal, hideModal] = useModal();
  const [qrStatus, setQrStatus] = useState(
    window.location.pathname.indexOf("/qr/") === 0 ? 1 : 0
  );
  const [emailErr, setEmailErr] = useState(false);
  useEffect(() => {
    traceIncidentSummaryLaunch(
      incidentType.authority.id,
      incidentType.incident.name,
      qrStatus
    );
    document.querySelector('body').setAttribute('style', 'background-color: #f8f8f8');
    document.querySelector('.segment').setAttribute('style', 'background-color: #f8f8f8');
    document.querySelector('.menu').setAttribute('style', 'background-color: #f8f8f8');

    return () => {
      document.querySelector('body').removeAttribute('style');
      document.querySelector('.segment').removeAttribute('style');
      document.querySelector('.menu').removeAttribute('style');
    }
  }, []);
  const sendReport = () => {
    setSubmitting(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.REACT_APP_GATEWAY_KEY);
    let additionalFields = [];
    for (const [name, value] of Object.entries(incidentDetails)) {
      if (name !== "description")
        additionalFields.push({ name: name, value: value });
    }

    const snapper = {
      firstName: userDetails.firstName.trim(),
      lastName: userDetails.lastName.trim(),
      email: userDetails.email.trim(),
      tel: userDetails.phone ? userDetails.phone.trim() : "",
      address: userDetails.address ? userDetails.address.trim() : "",
      postcode: userDetails.postcode ? userDetails.postcode.trim() : "",
      suburb: userDetails.suburb ? userDetails.suburb.trim() : "",
    };

    let rawService = {
      report: {
        authorityId: incidentType.authority.id,
        notes: incidentDetails.description,
        isCallOnly: incidentType.callScreen.isCallOnly,
        language: navigator.language,
        origin: window.location.origin,
      },
      incidentType: {
        id: incidentType.incident.id,
        name: incidentType.incident.name,
      },
      additionalFields: additionalFields,
      category: {
        id: incidentType.categories[0] && incidentType.categories[0].id,
        name: incidentType.categories[0] && incidentType.categories[0].name,
      },
      // device
      uploads: uploadImages,
      snapper: snapper,
    };
    if (embeded) {
      rawService.report.origin =
        document.referrer || window.location.ancestorOrigins[0];
    }

    if (qrCode) {
      rawService.report.qr = qrCode;
    } else {
      rawService.report.address = loc.address;
      rawService.report.lat = loc.lat;
      rawService.report.long = loc.lng;
    }

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(rawService),
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVICE}/authorities/reports`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        let payload = {
          authority_id: incidentType.authority.id,
          incident_type: incidentType.incident.name,
          incident_id: incidentType.incident.id,
          medium: 'Web',
          content: 'Standard report',
          report_id: result.id
        }
        if (qrStatus) {
          payload.asset_id = incidentType.asset.id;
          payload.asset_name =  incidentType.asset.name;
          payload.asset_type = incidentType.asset.type;
          payload.content = 'QR code';
        } else if (embedCode) {
          payload.source = embedCode;
        }
        traceIncidentSummaryReportSuccess(payload);
        setFinished(true);
        setSubmitting(false);
      })
      .catch((error) => {
        traceIncidentSummaryReportFail(
          incidentType.authority.id,
          incidentType.incident.name,
          qrStatus
        );
        setSubmitting(false);
        showModal("Service not available, please try again later");
      });
  };
  const validateCompulsoryFields = (userDetail, incidentType) => {
    if (userDetail["email"] && !validateEmail(userDetail["email"])) {
      return false
    }
    if (!userDetail["email"] || userDetail["email"].trim() === "") {
      return false;
    }
    setEmailErr(false)
    if (!userDetail["firstName"] || userDetail["firstName"].trim() === "") {
      return false;
    }
    if (!userDetail["lastName"] || userDetail["lastName"].trim() === "") {
      return false;
    }
    for (let f of incidentType.compulsoryFields) {
      if (f === "Telephone" || f === "Phone") {
        if (!userDetail["phone"] || userDetail["phone"].trim() === "") {
          return false;
        }
      } else if (f.toLowerCase() === "name") {
        continue;
      } else if (
        !userDetail[f.toLowerCase()] ||
        userDetail[f.toLowerCase()].trim() === ""
      ) {
        return false;
      }
    }
    return true;
  };
  const updateUserDetails = (name, value) => {
    setUserDetails((prev) =>
      Object.assign(Object.assign({}, prev), { [name]: value })
    );
  };
  const allFilled = useMemo(
    () => validateCompulsoryFields(userDetails, incidentType),
    [userDetails]
  );

  const onBlur = () => {
    if (!validateEmail(userDetails["email"])) {
      setEmailErr(true)
    } else {
      setEmailErr(false)
    }
  }

  let address = loc.address;

  try {
    address = qrStatus ? incidentType.asset.address : loc.address;
  } catch (e) {
    console.log("error: no asset property");
  }

  return (
    <div className="flex-layout">
      {qrCode && layoutInfo && layoutInfo.asset ? (
        <IncidentTypeTip asset={layoutInfo.asset} />
      ) : (
        <Caption>Report Summary</Caption>
      )}
      <InfoModal hide={hideModal} visible={visible} content={content} />
      <div className="w-full">
        {/* <InputField
          iconUrl={incidentType.authority.logo}
          value={incidentType.authority.fullName}
          textCls="pl-5"
          iconCls="w-12 h-12"
          containerCls="border-b rounded-lg h-32"
          readonly={true}
          labelName={"Authority"}
        /> */}
        {/* <InputField
          iconUrl={incidentType.incident.iconUrl}
          value={
            incidentType.incident.displayName || incidentType.incident.name
          }
          textCls="pl-5"
          containerCls="border-b rounded-lg h-32"
          readonly={true}
          labelName={"Incident Type"}
        /> */}
        <NewInputField 
          iconUrl={incidentType.incident.iconUrl}
          labelName={"Incident type"}
          value={
            incidentType.incident.displayName || incidentType.incident.name
          }
          readonly={true}
        />
        {/* <InputField
          value={address}
          readonly={true}
          textCls="pl-5 pt-12"
          iconCls="w-32 h-32"
          containerCls="border-b rounded-lg h-60"
          iconUrl={"/imgs/loc-icon.jpg"}
          multipleline={true}
          labelName={"Incident Location"}
        /> */}
        <NewInputField 
          iconUrl={"/imgs/loc-icon.png"}
          labelName={"Incident location"}
          value={address}
          readonly={true}
          iconWidth={18}
          iconHeight={22}
        />
        {/* <InputField
          labelName={"First Name"}
          textCls="border rounded-lg pl-5"
          value={userDetails.firstName}
          required={true}
          onChange={(e) => updateUserDetails("firstName", e.target.value)}
        /> */}
        <NewInputField 
          labelName={"First name"}
          value={userDetails.firstName}
          required={true}
          onChange={(e) => updateUserDetails("firstName", e.target.value)}
        />
        {/* <InputField
          labelName={"Last Name"}
          textCls="border rounded-lg pl-5"
          value={userDetails.lastName}
          required={true}
          onChange={(e) => updateUserDetails("lastName", e.target.value)}
        /> */}
        <NewInputField 
          labelName={"Last name"}
          value={userDetails.lastName}
          required={true}
          onChange={(e) => updateUserDetails("lastName", e.target.value)}
        />
        {/* <InputField
          labelName={"Email"}
          textCls="border rounded-lg pl-5"
          value={userDetails.email}
          required={incidentType.compulsoryFields.indexOf("Email") >= 0}
          onChange={(e) => updateUserDetails("email", e.target.value)}
          onBlur={onBlur}
        /> */}
        <NewInputField 
          labelName={"Email address"}
          value={userDetails.email}
          required={incidentType.compulsoryFields.indexOf("Email") >= 0}
          onChange={(e) => updateUserDetails("email", e.target.value)}
          onBlur={onBlur}
        />
        {emailErr ?  <div className="text-red-500">Please enter a valid email address</div> : null}
        {/* <InputField
          labelName={"Phone"}
          textCls="border rounded-lg pl-5"
          value={userDetails.phone}
          required={
            incidentType.compulsoryFields.indexOf("Phone") >= 0 ||
            incidentType.compulsoryFields.indexOf("Telephone") >= 0
          }
          onChange={(e) => updateUserDetails("phone", e.target.value)}
        /> */}
        <NewInputField 
          labelName={"Phone"}
          value={userDetails.phone}
          required={
            incidentType.compulsoryFields.indexOf("Phone") >= 0 ||
            incidentType.compulsoryFields.indexOf("Telephone") >= 0
          }
          onChange={(e) => updateUserDetails("phone", e.target.value)}
        />
        {qrStatus ? null : (
          // <InputField
          //   labelName={"Address"}
          //   textCls="border rounded-lg pl-5"
          //   value={userDetails.address}
          //   required={incidentType.compulsoryFields.indexOf("Address") >= 0}
          //   onChange={(e) => updateUserDetails("address", e.target.value)}
          // />
          <NewInputField 
            labelName={"Address"}
            value={userDetails.address}
            required={incidentType.compulsoryFields.indexOf("Address") >= 0}
            onChange={(e) => updateUserDetails("address", e.target.value)}
          />
        )}
        {qrStatus ? null : (
          <div className="md:flex  md:justify-between">
            <InputField
              labelName={"Suburb"}
              textCls="border rounded-lg pl-5"
              containerCls={"md:w-full"}
              value={userDetails.suburb}
              required={incidentType.compulsoryFields.indexOf("Suburb") >= 0}
              onChange={(e) => updateUserDetails("suburb", e.target.value)}
            />
            <InputField
              value={userDetails.postcode}
              textCls="border rounded-lg pl-5"
              containerCls="md:ml-4 md:w-full"
              labelName={"Postcode"}
              required={incidentType.compulsoryFields.indexOf("Postcode") >= 0}
              onChange={(e) => updateUserDetails("postcode", e.target.value)}
            />
          </div>
        )}
      </div>
      {submitting ? (
        <div>
          <Loader
            size="large"
            style={{
              position: "relative",
              display: "block",
              marginTop: 80,
            }}
          >
            Loading
          </Loader>
        </div>
      ) : (
        <button
          className={`btn-default md:w-56 ${
            allFilled ? "" : "btn-disable"
          } mb-14`}
          onClick={() => {
            if (allFilled) sendReport();
          }}
          style={{
            height: 54, 
            borderRadius: 16,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 17,
            width: '100%'
          }}
        >
          Send Report
        </button>
      )}
      <p className="text-center">
        By clicking <b>Send Report</b>, you agree to Snap Send Solve’s{" "}
        <a
          href="https://www.snapsendsolve.com/terms-of-use/"
          target="_blank"
          className="hyper-link"
        >
          Terms of Use
        </a>{" "}
        and{" "}
        <a
          href="https://www.snapsendsolve.com/privacy-policy/"
          target="_blank"
          className="hyper-link"
        >
          Privacy Policy
        </a>
        . If you’d like to know more about how we collect, use and hold your
        personal information, please refer to our{" "}
        <a
          href="https://www.snapsendsolve.com/privacy-collection-notice/ "
          target="_blank"
          className="hyper-link"
        >
          Privacy Collection Notice
        </a>
        .
      </p>
    </div>
  );
}
