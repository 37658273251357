export default function InfoModal({ visible, content, hide, locIcon }) {
  return (
    <div
      onClick={() => hide()}
      className={`ui page modals dimmer ${
        visible ? "visible" : "invisible"
      } active`}
    >
      <div
        className={`flex-layout  w-9/12	${
          locIcon ? "min-h-loc-modal" : ""
        }  md:w-1/3  relative	 bg-white	rounded-md	${locIcon ? "pt-10" : ""}`}
      >
        <svg
          onClick={() => hide()}
          className={`absolute right-5 cursor-pointer top-5 ${
            locIcon ? "" : "hidden"
          }`}
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.6673 0.344125C14.4547 0.131096 14.1661 0.0113766 13.8652 0.0113766C13.5643 0.0113766 13.2757 0.131096 13.0632 0.344125L7.50001 5.8959L1.93686 0.332749C1.72431 0.119719 1.43574 0 1.13481 0C0.833883 0 0.545315 0.119719 0.332765 0.332749C-0.110922 0.776435 -0.110922 1.49316 0.332765 1.93685L5.89591 7.49999L0.332765 13.0631C-0.110922 13.5068 -0.110922 14.2235 0.332765 14.6672C0.776451 15.1109 1.49318 15.1109 1.93686 14.6672L7.50001 9.10409L13.0632 14.6672C13.5068 15.1109 14.2236 15.1109 14.6673 14.6672C15.1109 14.2235 15.1109 13.5068 14.6673 13.0631L9.1041 7.49999L14.6673 1.93685C15.0996 1.50454 15.0996 0.776435 14.6673 0.344125Z"
            fill="#0E0E0E"
            fillOpacity="0.6"
          />
        </svg>
        <svg
          className={`${locIcon ? "" : "hidden"}`}
          width="66"
          height="88"
          viewBox="0 0 66 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M33 0C14.85 0 0 14.85 0 33C0 46.2 4.4 50.05 29.7 86.35C31.35 88.55 34.65 88.55 36.3 86.35C61.6 50.05 66 46.2 66 33C66 14.85 51.15 0 33 0ZM47.5836 41.7502C48.6578 42.8244 48.6578 44.5655 47.5836 45.6397L45.6397 47.5836C44.5655 48.6578 42.8244 48.6578 41.7502 47.5836L33 38.8334L24.2498 47.5836C23.1756 48.6578 21.4345 48.6578 20.3603 47.5836L18.4164 45.6397C17.3422 44.5655 17.3422 42.8244 18.4164 41.7502L27.1666 33L18.4164 24.2498C17.3422 23.1756 17.3422 21.4345 18.4164 20.3603L20.3603 18.4164C21.4345 17.3422 23.1756 17.3422 24.2498 18.4164L33 27.1666L41.7502 18.4164C42.8244 17.3422 44.5655 17.3422 45.6397 18.4164L47.5836 20.3603C48.6578 21.4345 48.6578 23.1756 47.5836 24.2498L38.8334 33L47.5836 41.7502Z"
            fill="#06A8F4"
          />
        </svg>
        <p className=" type-op-text-style pt-6 pb-6 w-11/12 md:font-medium	md:text-xl text-sm">
          {content}
        </p>
      </div>
    </div>
  );
}
