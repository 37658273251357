export default function InputField(props) {
  const v = () => {};
  return (
    <div
      className={`${props.containerCls} flex-col items-start ${props.splitHeight ? '' : 'h-28'} relative rounded-lg flex `}
    >
      {props.labelName ? (
        <div className="input-label-text-style mb-5 font-medium mt-6">
          {props.labelName}{" "}
          {props.required ? (
            <span className="font-semibold text-red-500">*</span>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className={`flex flex-row ${props.splitHeight ? '' : 'h-full'} w-full items-center`}>
        {props.iconUrl && (
          <img className={`${props.iconCls} w-7 h-7`} src={props.iconUrl} />
        )}
        {props.multipleline ? (
          <textarea
            readOnly={props.readonly}
            onChange={props.onChange}
            name={props.labelName}
            className={`${props.textCls} h-full w-full input-text-style`}
            defaultValue={props.value}
          />
        ) : (
          <input
            type="text"
            readOnly={props.readonly}
            required={props.required}
            onChange={props.onChange}
            className={`${props.textCls} ${props.splitHeight ? 'padding11' : 'h-full'} w-full input-text-style`}
            onClick={props.onClick}
            name={props.labelName}
            value={props.value}
            onBlur={props.onBlur || v}
          />
        )}
      </div>
    </div>
  );
}
