import {
  getUA,
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
} from "react-device-detect";

export const IsIOS = () => {
  if (isIOS13 || isIOS || isIPad13 || isIPhone13 || isIPod13) return true;
  if (
    getUA.toLowerCase().indexOf("ipad") >= 0 ||
    getUA.toLocaleLowerCase().indexOf("iphone") >= 0
  )
    return true;
  return false;
};

export const IsIE11 = () => {
  return window.navigator.userAgent.match(/MSIE|Trident/) !== null;
};

export const validateEmail = (email) => {
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(email);
};
